import { Helmet } from 'react-helmet-async';
import { DefaultHelmet } from './default-helmet';
import { UaHelmet } from './ua-helmet';
import { themeCssVariables as defaultTheme } from './default-theme';
import { themeCssVariables as uaTheme } from './ua-theme';

export function CountryHelmet({ isUkraine }: { isUkraine: boolean }) {
  const rootStyles = Object.entries(isUkraine ? uaTheme : defaultTheme)
    .map(([variable, value]) => `${variable}: ${value};`)
    .join('\n');
  return (
    <>
      <Helmet>
        <style>{`:root { ${rootStyles} }`}</style>
      </Helmet>
      {isUkraine ? <UaHelmet /> : <DefaultHelmet />}
    </>
  );
}
