import './ListItem.scss';
import { useTranslation } from 'react-i18next';
import { useCheckCountries } from 'src/entities/countries';
import UserPicImage from './assets/reviews-user-pic.png';
import reviewsLogoImage from './assets/reviews-logo.png';
import uaCompanyLogo from './assets/ua-company-logo.svg?url';

function ReviewsListItem({ review }) {
  const { t } = useTranslation();
  const { isUkraine } = useCheckCountries();
  return (
    <div className="reviews-list-item">
      <div className="reviews-list-item__row">
        <div className="reviews-list-item__image">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={UserPicImage}
            alt={t('ListItem.user_image')}
          />
        </div>
        <div className="reviews-list-item__content">
          <h2 className="reviews-list-item__author">{review.name}</h2>
          <div className="reviews-list-item__date">{review.created_at}</div>
          {review.city_name ? (
            <div className="reviews-list-item__place">
              <span className="reviews-list-item__title__city">
                {review.city_name}
              </span>
            </div>
          ) : undefined}
          <p
            className="reviews-list-item__text"
            dangerouslySetInnerHTML={{ __html: review.comment }}
          />
        </div>
      </div>
      {review.response ? (
        <div className="reviews-list-item__row reviews-list-item__row--response">
          <div className="reviews-list-item__image">
            <img
              itemScope
              itemType="https://schema.org/ImageObject"
              src={isUkraine ? uaCompanyLogo : reviewsLogoImage}
              alt={t('ListItem.user_image')}
            />
          </div>
          <div className="reviews-list-item__content">
            <h2 className="reviews-list-item__author reviews-list-item__author--response">
              {t('localization.companyName')}
            </h2>
            <p
              className="reviews-list-item__text"
              dangerouslySetInnerHTML={{ __html: review.response }}
            />
          </div>
        </div>
      ) : undefined}
    </div>
  );
}

export default ReviewsListItem;
