import { YMaps } from '@pbe/react-yandex-maps';
import { avaliabaleLangs } from 'src/shared/config/avaliabaleLangs';
import { useTranslation } from 'react-i18next';
import { useMapType } from 'shared/third-party-libs/commonMap';

const yandexMapsConfig = {
  src: 'https://api-maps.yandex.ru/2.1/',
  apikey: '0100e5f4-80d2-451a-9b8a-3cd460dc527b',
  suggest_apikey: '17724b59-7929-45f7-b45c-95c854403a45',
};

const yandexAvailableLang = [
  'ru_RU',
  'en_US',
  'uk_UA',
  'tr_TR',
  'en_RU',
  'ru_UA',
] as const;

export function YMapsProvider({ children }: { children: JSX.Element }) {
  const { i18n } = useTranslation();
  const { isYandexMap } = useMapType();

  if (!isYandexMap) return children;

  const defaultLanguage = avaliabaleLangs.en;
  const currentLanguage = i18n.language;
  const appLang =
    avaliabaleLangs[currentLanguage as keyof typeof avaliabaleLangs] ||
    defaultLanguage;

  const lang =
    yandexAvailableLang.find((item) => item === appLang) || defaultLanguage;

  return (
    <YMaps
      query={{
        apikey: yandexMapsConfig.apikey,
        suggest_apikey: yandexMapsConfig.suggest_apikey,
        lang,
      }}
    >
      {children}
    </YMaps>
  );
}
