import './Quality.scss';
import { Component } from 'react';
import axios from 'axios';
import validators from 'modules/form-validators';
import mask from 'modules/mask/phone';
import ResponseError from 'modules/response/error';
import globalStore from 'modules/global-store';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import { withCheckCountries } from 'src/entities/countries';
import RadioButton from 'src/shared/ui/Radio/Radio';
import { InputTime } from 'src/shared/ui/inputs/inputTime';
import { InputOrder } from 'src/shared/ui/inputs/inputOrder';
import Textarea from '../Elements/Textarea/Textarea';
import initState from './initState.json';

class Quality extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  changeTime = ({ target }) => {
    const { form } = this.state;
    const { reviews } = form;
    const { name, value } = target;
    console.log(name, value);

    reviews.times.forEach((item) => {
      if (item.name === name) item.value = value;
    });
    this.setState({ form });
  };

  changeRadio = ({ target }) => {
    const { form } = this.state;
    const { reviews } = form;
    const { name, value } = target;

    reviews.selects.forEach((item) => {
      if (item.name === name) item.value = value;
    });
    this.setState({ form });
  };

  changeReviews = (event) => {
    const { target } = event;
    const { form } = this.state;
    const current_city = globalStore.get('current_city');
    const { name } = target;
    if (target.name === 'phone') {
      if (+target.value === 9 && current_city.domain === 'ru')
        target.value = '+79';

      mask(event);
    }
    form[name] = target.value;

    this.setState({ form });
  };

  onSubmitSuccess = () => {
    const { onSuccess } = this.props;
    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  };

  formSubmitHandler = (event) => {
    event.preventDefault();

    const formData = {};
    const {
      form: { order_hash, phone, reviews, comment },
      invalidFields,
      checkedInfo,
    } = this.state;

    formData.order_hash = order_hash;
    formData.phone = phone;

    for (const type in reviews) {
      reviews[type].map((item) => {
        formData[item.name] = item.value;
        formData[`${item.name}_comment`] = item.comment;
      });
    }

    formData.comment = comment;

    if (Object.keys(invalidFields).length > 0 || !checkedInfo) {
      this.setState({ formSubmitted: true });
    } else {
      axios
        .post('/review/submit_quality', formData)
        .then(() => {
          this.onSubmitSuccess();
        })
        .catch((error) => {
          const responseError = new ResponseError(error);

          if (responseError.code === 500)
            this.setState({ errorMessage: responseError.message });
        });
    }
  };

  renderRows = (t) => {
    const {
      form: {
        reviews: { selects, times },
      },
    } = this.state;
    const selectItems = selects.map(({ name, value }, index) => {
      return (
        <tr key={`selects-${name}-${index}`}>
          <td className="quality-num-title">{t(`Quality.${name}_title`)}</td>
          <td>
            {[1, 2, 3, 4, 5].map((num) => (
              <RadioButton
                key={num.toString()}
                checked={num === +value}
                name={name}
                id={`${name}-${num}`}
                onChange={this.changeRadio}
                value={num}
              >
                {num}
              </RadioButton>
            ))}
          </td>
        </tr>
      );
    });

    const timeItems = (
      <tr className="timeItems timeItems--form">
        {times.map(({ name }, index) => {
          return (
            <td className="timeItems__item" key={`times-${name}-${index}`}>
              <div className="timeItems__title">
                {t(`Quality.${name}_title`)}
              </div>
              <div className="timeItems__input-wrap">
                <InputTime
                  placeholder="00:00"
                  onInput={this.changeTime}
                  name={name}
                  autoComplete="off"
                />
              </div>
            </td>
          );
        })}
      </tr>
    );

    return (
      <tbody>
        {selectItems}
        {timeItems}
      </tbody>
    );
  };

  validMessage = (name) => {
    const { formSubmitted, invalidFields } = this.state;
    return formSubmitted && invalidFields[name];
  };

  toggleShow = () => {
    this.setState((prevState) => ({ checkedInfo: !prevState.checkedInfo }));
  };

  render() {
    const {
      form: { order_hash, phone, comment },
      formSubmitted,
      invalidFields,
      form,
      formValidators,
      errorMessage,
      checkedInfo,
    } = this.state;
    const { t, isUkraine } = this.props;
    validators.validate(form, formValidators, invalidFields);
    return (
      <div className="quality">
        <form
          action=""
          className="form form-quality"
          onSubmit={this.formSubmitHandler}
        >
          <div className="form__title">
            {t('Quality.evaluation of the quality of work')}
          </div>
          <div className="form__title--sub">{t('Quality.sub title')}</div>
          <div className="form-quality-title__input">
            <div className="form-quality-id">
              <div
                className={`input-container ${
                  this.validMessage('order_hash') || errorMessage.length
                    ? 'error'
                    : undefined
                }`}
              >
                {this.validMessage('order_hash') ? (
                  <label>{t('Quality.order number is not correct')}</label>
                ) : errorMessage.length ? (
                  <label> {t('Quality.Order not found')}</label>
                ) : (
                  <label>{t('Quality.quality_order_id_title')}</label>
                )}
                <InputOrder
                  onInput={this.changeReviews}
                  name="order_hash"
                  value={order_hash}
                />
              </div>
            </div>
            <div className="form-quality-phone">
              <div
                className={`input-container ${
                  this.validMessage('phone') ? 'error' : undefined
                }`}
              >
                {this.validMessage('phone') ? (
                  <label>{t('Quality.phone number is not correct')}</label>
                ) : (
                  <label>{t('Quality.quality_phone_title')} </label>
                )}
                <input
                  type="tel"
                  onInput={this.changeReviews}
                  onKeyDown={this.changeReviews}
                  onPaste={this.changeReviews}
                  name="phone"
                  className=""
                  defaultValue={phone}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
          <table className="table">{this.renderRows(t)}</table>
          <div className="form-quality-about">
            <label>{t('Quality.qualityCommentTitle')}</label>
            <Textarea
              name="comment"
              className="form__textarea"
              value={comment}
              onChange={this.changeReviews}
              required
              autoComplete="off"
            />
          </div>
          <div className="page-quality__checkbox">
            <div
              className={cn('checkbox-box__checkbox-container', {
                error: !checkedInfo,
                ua: isUkraine,
              })}
            >
              <input
                type="checkbox"
                id="info"
                name="info_personal"
                checked={checkedInfo}
                onChange={this.toggleShow}
              />
              <label htmlFor="info"> {t('Quality.info')}</label>
            </div>
          </div>
          {(formSubmitted && Object.keys(invalidFields).length > 0) ||
          errorMessage.length ? (
            <div className="invalidFields">
              * {t('Quality.not all fields are filled out')}
            </div>
          ) : undefined}
          <div className="form-quality-action">
            <button
              type="button"
              className="sw-button"
              disabled={!checkedInfo}
              style={{ display: 'block', margin: 'auto' }}
            >
              {t('Quality.send_button')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation()(withCheckCountries(Quality));
