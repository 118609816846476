import '../Checkbox.scss';
import cn from 'classnames';
import { useCheckCountries } from 'src/entities/countries';

const states = ['none', 'up', 'down'];

function SortCheckbox(props) {
  const { isUkraine } = useCheckCountries();
  const getSortState = () => {
    const { sortState } = props;
    if (Object.values(SortCheckbox.STATE).includes(sortState)) {
      return sortState;
    }
    return SortCheckbox.STATE.NONE;
  };

  const toggle = () => {
    let sortState = getSortState();
    const { name = 'defaultName' } = props;
    sortState = ++sortState % states.length;
    if (props.onToggle) {
      props.onToggle({ name, sortState });
    }
  };

  const { title = '' } = props;
  const sortState = getSortState();
  return (
    <div className="checkbox">
      <span
        style={{ marginTop: 0 }}
        className={cn('sort', `checkbox__${states[sortState]}`, {
          ua: isUkraine,
        })}
        onClick={toggle}
      >
        {title}
      </span>
    </div>
  );
}
SortCheckbox.STATE = {
  NONE: 0,
  UP: 1,
  DOWN: 2,
};

export default SortCheckbox;
