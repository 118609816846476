import { langDetector } from './lang-detector';

const isServer = typeof window === 'undefined';

export const options = {
  fallbackLng: false as const,
  load: 'languageOnly' as const, // we only provide en, de -> no region specific locals like en-US, de-DE
  // have a common namespace used around the full app
  interpolation: {
    escapeValue: false,
  },
  debug: false,
  detection: {
    cookieOptions: {
      path: '/',
      maxAge: 31536000 /* one year, add to the environment, as possible */,
    },
    // order and from where user language should be detected
    order: isServer ? [langDetector.name] : ['cookie'],
    // keys or params to lookup language from
    lookupQuerystring: 'lang',
    // lookupSession: 'lang',
    lookupCookie: 'lang',
    // cache user language, you can define if an how the detected language should be "saved" => 'cookie' and/or 'session'
    caches: ['cookie'], // ['cookie']
    ignoreCase: true, // ignore case of detected language
  },
};
