import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import NoPhoto from './assets/no_photo.svg?url';

export function InternalImage({
  src,
  className,
  alt,
  width,
  height,
  loading = 'lazy',
}: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
  return (
    <img
      alt={alt}
      width={width}
      height={height}
      loading={loading}
      src={src}
      className={className}
      onError={(e) => {
        e.currentTarget.src = NoPhoto;
      }}
    />
  );
}
