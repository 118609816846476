import Do from 'frontend/Components/Products/CardWok/wok-constructor/Do';
import { useCheckCountries } from 'src/entities/countries';
import cn from 'classnames';
import Case from '../../../Case/Case';

function ToppingSelect({
  topping,
  onSelect,
  currentCount,
  currency,
  maxAvailableCount,
}) {
  return (
    <select
      className="card-wok__toppings_selector"
      value={currentCount}
      data-topping-id={topping.id}
      onChange={onSelect}
    >
      {Do(maxAvailableCount).collect((i) => (
        <option
          value={i + 1}
          key={`${topping.id}-${i}`}
          className="modal-toppings__num"
        >
          {`${i + 1} x ${topping.price} ${currency}`}
        </option>
      ))}
    </select>
  );
}

function ToppingCheckbox({
  toppingSelected,
  disabled,
  name,
  id,
  onChangeCheckboxHandler,
  title,
}) {
  const { isUkraine } = useCheckCountries();

  return (
    <div
      className={`card-wok__input-row ${
        !toppingSelected && disabled ? 'card-wok__input-row--disabled' : ''
      }`}
    >
      <input
        id={name}
        name={name}
        type="checkbox"
        value={id}
        className={cn('card-wok__checkbox', { ua: isUkraine })}
        onChange={onChangeCheckboxHandler}
        checked={toppingSelected}
        disabled={!toppingSelected && disabled}
      />
      <label
        htmlFor={name}
        title={title}
        className={`card-wok__label${
          toppingSelected ? ' card-wok__label--short' : ''
        }`}
      >
        {title}
      </label>
    </div>
  );
}

function ToppingOptionButton({
  topping,
  toppingSelected,
  disabled,
  name,
  onChangeCheckboxHandler,
  onSelectHandler,
  currentCount,
  currency,
  maxAvailableCount,
}) {
  return (
    <li className="card-wok__option with-select">
      <ToppingCheckbox
        toppingSelected={toppingSelected}
        disabled={disabled}
        name={name}
        id={topping.id}
        onChangeCheckboxHandler={onChangeCheckboxHandler}
        title={topping.title}
      />

      <Case of={toppingSelected}>
        <ToppingSelect
          topping={topping}
          maxAvailableCount={maxAvailableCount}
          onSelect={onSelectHandler}
          currentCount={currentCount}
          currency={currency}
        />
      </Case>

      <Case of={!toppingSelected}>
        <div className="card-wok__price-container">
          <p className="card-wok__price">
            {topping.price}&nbsp;{currency}
          </p>
        </div>
      </Case>
    </li>
  );
}

export default ToppingOptionButton;
