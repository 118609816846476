import { useTranslation } from 'react-i18next';

function SupportChat({
  isRussia,
  isUkraine,
}: {
  isRussia: boolean;
  isUkraine: boolean;
}) {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <>
      {isUkraine && (
        <script
          async
          type="text/javascript"
          src="//widgets.binotel.com/chat/widgets/DKLefGNgIaKgVeYLgVyL.js"
        />
      )}
      {isRussia && (
        <script
          async
          src="//cdn5.helpdeskeddy.com//js/contact-widget.js"
          id="hde-contact-widget"
          data-assets-host="//cdn5.helpdeskeddy.com/"
          data-host="incall.helpdeskeddy.com"
          data-lang={language}
        />
      )}
    </>
  );
}

export default SupportChat;
