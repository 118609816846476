import Template from 'frontend/Pages/Template/Template';
import {
  useCheckCountries,
  useSelectCountry,
  useSelectCountryMainDomain,
} from 'src/entities/countries';
import { Header } from 'frontend/widgets/header';
import MobileMenu from 'frontend/Components/MainMenu/MobileMenu/MobileMenu';
import { Footer } from 'frontend/widgets/footer';
import { IRoute, RoutesWithSubRoutes } from 'shared/lib/routes-with-sub-routes';
import { useResetCartOnChangeCity } from 'features/cart-reset';
import { useCartQtyHandler } from 'features/add-product-to-cart/useCartQtyHandler';
import { useSelectAddressFirst } from 'features/modals/select-address-first';
import { useCityChange, useSelectCity } from 'src/entities/cities';
import { useFirstShowConfirmCityModal } from 'features/modals/confirm-city-modal';
import { useSelectCityModal } from 'features/modals/select-city-modal';
import { YMapsProvider } from 'app/providers/ymaps-provider';
import SupportChat from 'frontend/widgets/support-chat/SupportChat';
import Cookie from 'frontend/widgets/cookie/Cookie';
import { SidebarDesktop } from 'frontend/widgets/sideBar';
import { DesktopMenu, useSelectMenu } from 'src/entities/menu';
import { ModalProvider } from './providers/modal-provider';
import { AppHelmet, CountryHelmet } from './helmet';
// import Hamster from 'frontend/widgets/hamster/Hamster';

export function CityLayout({ routes }: { routes: IRoute[] }) {
  const { hasCityChanged } = useCityChange();
  useResetCartOnChangeCity(hasCityChanged);
  const selectCityModal = useSelectCityModal();
  useFirstShowConfirmCityModal(selectCityModal.show);
  useCartQtyHandler();
  useSelectAddressFirst();
  const mainDomain = useSelectCountryMainDomain();
  const city = useSelectCity();
  const country = useSelectCountry();
  const isCheckCountries = useCheckCountries();
  const menu = useSelectMenu();
  return (
    <YMapsProvider>
      <ModalProvider>
        <AppHelmet
          mainDomain={mainDomain}
          formatLocale={city.format_locale}
          countryName={country.name}
          cityName={city.city_name}
          cityPhone={city.phone}
          cityTextId={city.text_id}
          worktimeFrom={city.info.worktime.from}
          worktimeTo={city.info.worktime.to}
        />
        <CountryHelmet {...isCheckCountries} />
        <Header countryId={country.id} isCheckCountries={isCheckCountries} />
        <SidebarDesktop>
          <DesktopMenu mainDomain={mainDomain} menu={menu} />
        </SidebarDesktop>
        <MobileMenu />
        <RoutesWithSubRoutes routes={routes} />
        <Footer {...isCheckCountries} />
        <Template />
        {/* <Hamster /> */}
        <Cookie />
        {city.chat_enabled && <SupportChat {...isCheckCountries} />}
      </ModalProvider>
    </YMapsProvider>
  );
}
