import { useLocation } from 'react-router-dom';
import { SubscribeModalButton } from './subscribe-modal-button';
import { TimeAndPhoneInfo } from './time-and-phone-info';
import { FooterLinks } from './footer-links';
import { SocialLinks } from './social-links';
import { Copyright } from './copyright';
import styles from './footer.module.scss';
import { UaPayment, ByPayment } from './payment';

export function Footer({
  isUkraine,
  isBelarus,
  isArmenia,
}: {
  isUkraine: boolean;
  isBelarus: boolean;
  isArmenia: boolean;
}) {
  const location = useLocation();
  const isRenderFooter = () => {
    const renderConditions = ['arguments', 'about'];

    const [, name] = location.pathname.match(/([A-z]+)(\/?)$/) || [];

    return !renderConditions.includes(name);
  };

  if (!isRenderFooter()) return null;

  return (
    <footer
      itemScope
      itemType="http://schema.org/WPFooter"
      className={styles.root}
    >
      <FooterLinks />
      <div className={styles.socialsAndTimeWrapper}>
        <SocialLinks />
        <TimeAndPhoneInfo />
      </div>
      {isUkraine && <UaPayment />}
      {isBelarus && <ByPayment />}
      <div className={styles.additioinal}>
        <Copyright isArmenia={isArmenia} isUkraine={isUkraine} />
        {!isUkraine && <SubscribeModalButton />}
      </div>
    </footer>
  );
}
