import { Burger } from 'frontend/widgets/header/burger/Burger';
import styles from './mobile.module.scss';
import { CartLink } from '../cartLink/CartLink';

function Mobile({
  decoration,
  logo,
}: {
  decoration: { left?: JSX.Element; right?: JSX.Element };
  logo: JSX.Element;
}) {
  return (
    <div className={styles.root}>
      <Burger />
      {decoration.left}
      {logo}
      {decoration.right}
      <CartLink isMobile />
    </div>
  );
}

export { Mobile };
