import { useCheckCountries } from 'src/entities/countries';
import './Radio.scss';
import { ChangeEvent, PropsWithChildren } from 'react';
import cn from 'classnames';

interface IRadioButton {
  id: string;
  name: string;
  checked: boolean;
  addClass?: string;
  value?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

function Radio({
  id,
  name,
  checked,
  onChange,
  children,
  value,
  addClass,
}: PropsWithChildren<IRadioButton>) {
  const { isUkraine } = useCheckCountries();
  return (
    <div className={cn(addClass)}>
      <input
        type="radio"
        id={id}
        name={name}
        className={cn('sw-radio', { ua: isUkraine })}
        checked={!!checked}
        onChange={(event) => {
          if (onChange) onChange(event);
        }}
        value={value}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
}

export default Radio;
