import { useCheckCountries } from 'src/entities/countries';
import cn from 'classnames';
import styles from './newMark.module.scss';

interface INewMark {
  isNewProduct: boolean;
  view_type: string;
}

function NewMark({ isNewProduct, view_type }: INewMark) {
  const { isUkraine } = useCheckCountries();
  if (!isNewProduct) return null;

  const newRoot = cn(styles.root, {
    [styles.ua]: isUkraine,
    [styles.grid]: view_type === 'grid',
  });

  return <div className={newRoot}>{isUkraine ? '' : 'new'}</div>;
}

export default NewMark;
