import './ToppingsModal.scss';
import './ToppingsModalMobile.scss';
import { Component } from 'react';
import Modal from 'react-modal';
import { modalEmitter } from 'modules/event-emitters';
import { ranks } from 'modules/common';
import { withTranslation } from 'react-i18next';
import { withBreakpoints } from 'frontend/hocs';
import { withCheckCountries, withCurrency } from 'src/entities/countries';
import cn from 'classnames';
import modernModal from '../../../../frontend/Components/Modals/ModernModal';
import Button from '../../../../frontend/Components/Elements/Buttons/Button';
import ChangeQty from '../../../../frontend/Components/Products/Components/ChangeQty/ChangeQty';
import {
  disableOptions,
  getChild,
  getDisabledStatus,
  getOptions,
  getTotalToppings,
  getUniqueTypeToppings,
} from '../../lib/utils';

const customStyles = {
  overlay: {
    zIndex: 101,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '1000px',
    width: '80%',
    top: '5%',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    marginBottom: '30px',
    overflow: 'visible',
    padding: '30px 4vw 36px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
  },
};

const mobileStyles = {
  overlay: {
    zIndex: 101,
    backgroundColor: 'white',
    overflowY: 'auto',
  },
  content: {
    maxWidth: 'none',
    width: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    marginBottom: '0',
    overflow: 'hidden',
    padding: '56px 4vw 140px',
    border: 'none',
    boxShadow: 'none',
    boxSizing: 'border-box',
    height: '100%',
  },
};

class ToppingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      product: null,
      toppings: [],
      selected: [],
      total: 0,
      typesOfToppings: [],
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    modalEmitter.addListener('Modal.Toppings.Open', this.openModal);
    modalEmitter.addListener('Modal.Toppings.Close', this.closeModal);
  }

  componentWillUnmount() {
    modalEmitter.removeListener('Modal.Toppings.Open', this.openModal);
    modalEmitter.removeListener('Modal.Toppings.Close', this.closeModal);
  }

  onAddToCart = () => {
    this.setState({
      modalIsOpen: false,
    });
    modernModal.closeModal();
    this.resetToppings();
  };

  onCheckHandler = ({ target }, toppingId) => {
    function closest(el, selector) {
      let node = el;

      while (node) {
        if (node.matches(selector)) return node;
        node = node.parentElement;
      }
      return null;
    }

    const parent = closest(target, '.modal-toppings__item');
    const select = parent.querySelector('select');

    let { selected } = this.state;
    const { toppings, product, typesOfToppings } = this.state;
    const isChecked = target.checked;
    const topping = toppings.find(({ product }) => product.id === +toppingId);
    const selectedType = topping.type;

    if (isChecked) {
      selected.push(toppingId);
      topping.cart_count = 1;
    } else {
      select.selectedIndex = 0;
      topping.cart_count = 0;
      selected = selected.filter((id) => {
        return id !== toppingId;
      });
    }
    const { total, updatedTypesOfToppings } = getTotalToppings(
      selectedType,
      toppings,
      typesOfToppings,
      product
    );
    this.setState({
      selected,
      toppings,
      total,
      typesOfToppings: updatedTypesOfToppings,
    });
  };

  onSelectHandler = (e, toppingId) => {
    const { toppings, typesOfToppings, product } = this.state;
    const { options } = e.currentTarget;
    const topping = toppings.find(({ product }) => product.id === +toppingId);

    const selectedType = topping.type;

    topping.cart_count = +options[options.selectedIndex].value;

    const { total, remaining, updatedTypesOfToppings } = getTotalToppings(
      selectedType,
      toppings,
      typesOfToppings,
      product
    );
    disableOptions(
      options,
      remaining,
      selectedType,
      typesOfToppings,
      topping.cart_count
    );

    this.setState({
      toppings,
      total,
      typesOfToppings: updatedTypesOfToppings,
    });
  };

  openModal = ({ product, toppings = [] }) => {
    const typesOfToppings = getUniqueTypeToppings(product);

    const updateToppings = toppings.map((topping) => {
      const matchingToppings = product.product_toppings.find(
        (item) => item.product_id === topping.id
      );
      return {
        product: topping,
        cart_count: 0,
        max_count: matchingToppings.type.max_toppings,
        type: matchingToppings.type.name,
      };
    });

    this.setState({
      modalIsOpen: true,
      product,
      toppings: updateToppings,
      typesOfToppings,
    });

    modernModal.openModal();
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      product: null,
      toppings: [],
      selected: [],
      total: 0,
    });
    modernModal.closeModal();
  };

  resetToppings = () => {
    const { toppings, typesOfToppings } = this.state;
    toppings.forEach((topping) => {
      topping.cart_count = 0;
    });

    const updatedTypesOfToppings = typesOfToppings.map((type) => ({
      ...type,
      totalTypeTopping: 0,
      remainingTypeTopping: type.maxTypeTopping,
    }));

    this.setState({
      selected: [],
      toppings,
      total: 0,
      typesOfToppings: updatedTypesOfToppings,
    });
    const checkboxes = document.querySelectorAll('.modal-toppings__checkbox');

    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    const selects = document.querySelectorAll('.modal-toppings__quantity');
    selects.forEach((select) => {
      select.selectedIndex = 0;
    });
  };

  renderProductWithToppings() {
    const { product, toppings, selected, total, typesOfToppings } = this.state;
    const { t, currency } = this.props;

    if (product && toppings) {
      let totalPrice = +product.price;
      const isUa = this.props.isUkraine;
      return (
        <div className="modal-toppings">
          <div className="mobile-header">
            <button
              type="button"
              onClick={this.closeModal}
              className="close-modal-btn"
              aria-label="close"
            />
            <h2 className="form-title">{t('ToppingsModal.toppings')}</h2>
          </div>
          <ul className="modal-toppings__list">
            {toppings.map(
              ({ product: topping, cart_count, max_count, type }) => {
                const options = getOptions(max_count);
                const disabled = getDisabledStatus(
                  type,
                  total,
                  typesOfToppings,
                  product
                );
                const isChecked = !!selected.find((id) => +id === +topping.id);
                totalPrice += +topping.price * +cart_count;

                return (
                  <li
                    className="modal-toppings__item"
                    key={`topp-${topping.id}`}
                  >
                    <div className="modal-toppings__topping">
                      <input
                        className="modal-toppings__checkbox"
                        type="checkbox"
                        name={topping.text_id}
                        id={topping.id}
                        onChange={(e) => this.onCheckHandler(e, topping.id)}
                        disabled={disabled && !isChecked}
                      />
                      <label
                        className={cn('modal-toppings__name', { ua: isUa })}
                        htmlFor={topping.id}
                      >
                        {topping.title}
                      </label>
                    </div>
                    <div className="modal-toppings__options">
                      <select
                        className={`modal-toppings__quantity ${
                          !isChecked ? 'modal-toppings__quantity--hidden' : ''
                        }`}
                        name={`${topping.text_id}-select`}
                        id={`${topping.id}-select`}
                        onMouseDown={(e) => this.onSelectHandler(e, topping.id)}
                        onChange={(e) => this.onSelectHandler(e, topping.id)}
                      >
                        {options.map((item, index) => {
                          return (
                            <option
                              defaultValue={index === 0}
                              value={item}
                              key={`toppings-${index}-${item}`}
                              className="modal-toppings__num"
                            >
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      <div
                        className={`modal-toppings__price${
                          isChecked ? '' : ' modal-toppings__price--inactive'
                        }`}
                      >
                        {isChecked
                          ? cart_count > 0
                            ? topping.price * cart_count
                            : topping.price
                          : topping.price}
                        &nbsp;
                        {currency}
                      </div>
                    </div>
                  </li>
                );
              }
            )}
          </ul>
          <div className="mobile-footer">
            <div className="modal-toppings__product">
              <div className="modal-toppings__product-title">
                {product.title}
              </div>
              <div className="modal-toppings__product-price">
                {`${product.price} ${currency}`}
              </div>
            </div>

            <div className="modal-toppings__total">
              <span className="modal-toppings__total--price">
                {t('ToppingsModal.totalPrice')}
              </span>
              <span>
                {ranks(totalPrice)} {` ${currency}`}
              </span>
            </div>
            <div className="button-case">
              <Button
                onClick={this.resetToppings}
                className="reset-button"
                disabled={!selected.length}
              >
                {t('ToppingsModal.reset')}
              </Button>
              <ChangeQty
                product={product}
                child={getChild(toppings)}
                changeQtyCallback={this.onAddToCart}
                render={({ add }) => {
                  return (
                    <Button onClick={add} className="to-cart-button">
                      {t('ToppingsModal.add_to_order')}
                    </Button>
                  );
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { modalIsOpen } = this.state;
    const { isMobile } = this.props;

    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        style={isMobile ? mobileStyles : customStyles}
        contentLabel="modal-toppings"
      >
        {this.renderProductWithToppings()}
      </Modal>
    );
  }
}

export default withTranslation()(
  withBreakpoints(withCurrency(withCheckCountries(ToppingsModal)))
);
