import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

function getWorkerPath(language: string) {
  switch (language) {
    case 'ua':
      return '/public/ss/service-worker-ua.js';
    case 'pl':
      return '/public/ss/service-worker-pl.js';
    default:
      return '/public/service-worker.js';
  }
}

const register = (language: string) => {
  const workerPath = getWorkerPath(language);

  if ('serviceWorker' in navigator) {
    // Declaring a broadened scope
    navigator.serviceWorker.register(workerPath);
    // .then(
    //   (registration) => {
    //     // The registration succeeded because the Service-Worker-Allowed header
    //     // had set a broadened maximum scope for the service worker script
    //     console.log('Service worker registration succeeded:', registration);
    //   },
    //   (error) => {
    //     // This happens if the Service-Worker-Allowed header doesn't broaden the scope
    //     console.error(`Service worker registration failed: ${error}`);
    //   }
    // );
    // } else {
    //   console.error('Service workers are not supported.');
  }
};
export function SwRegister() {
  const { i18n } = useTranslation();
  return (
    <Helmet>
      <script defer>{register(i18n.language)}</script>
    </Helmet>
  );
}
