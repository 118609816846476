import cn from 'classnames';
import { useCheckCountries } from 'src/entities/countries';
import { SmartLink } from 'shared/ui/smart-link';
import type { ISmartLink } from 'shared/ui/smart-link';
import styles from './styles.module.scss';

interface ILinkButton extends ISmartLink {
  isFullWidth?: boolean;
  variant?: 'contained' | 'outlined';
}

export function LinkButton({
  className,
  children,
  variant = 'outlined',
  isFullWidth = false,
  ...props
}: ILinkButton) {
  const { isUkraine } = useCheckCountries();

  return (
    <SmartLink
      className={cn(styles.swButton, className, {
        [styles.ua]: isUkraine,
        [styles.fullWidth]: isFullWidth,
        [styles.contained]: variant === 'contained',
        [styles.outlined]: variant === 'outlined',
      })}
      {...props}
    >
      {children}
    </SmartLink>
  );
}
