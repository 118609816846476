import './Add.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';
import { modalEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import { withCheckCountries } from 'src/entities/countries';
import cn from 'classnames';
import ReviewsAddModal from '../AddModal/AddModal';

class ReviewsAdd extends Component {
  constructor(props) {
    super(props);

    const { city_stores, store } = props;

    this.state = {
      city_stores: city_stores || [],
      store,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { city_stores, store } = nextProps;

    this.setState({ city_stores, store });
  }

  @autobind
  showAddFormModal(e) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
    const { store } = this.state;
    modalEmitter.emit('Review.Modal.Open', store);
  }

  render() {
    const { city_stores } = this.state;
    const { t, location, isUkraine } = this.props;

    const addReview = cn('reviews-add reviews-tabs__item', { ua: isUkraine });
    return (
      <div className={addReview}>
        <div onClick={this.showAddFormModal}>{t('Add.add_review')}</div>
        <ReviewsAddModal city_stores={city_stores} location={location} />
      </div>
    );
  }
}

export default withTranslation()(withCheckCountries(ReviewsAdd));
