import { Media } from 'src/shared/lib/media';
import loadable from '@loadable/component';
import { holidayCheck } from 'src/entities/holyday-decorations';
import cn from 'classnames';
import { useCheckCountries } from 'src/entities/countries';
import { Desktop } from './desktop/Desktop';
import { Mobile } from './mobile/Mobile';
import styles from './header.module.scss';
import MobileNavigation from './mobile/mobileNavigation/MobileNavigation';
import { Logo } from './logo/Logo';

const DecorationSpan = loadable(
  () =>
    import('src/entities/holyday-decorations').then(
      ({ DecorationSpan: Decoration }) => Decoration
    ),
  { ssr: false }
);

export function Header({
  countryId,
  isCheckCountries,
}: {
  countryId: number;
  isCheckCountries: ReturnType<typeof useCheckCountries>;
}) {
  const holydayNameForLogo = holidayCheck({
    countryId,
    type: 'logo',
  });
  const logo = (
    <Logo
      holydayName={holydayNameForLogo}
      isCheckCountries={isCheckCountries}
    />
  );

  const holydayNameForHeader = holidayCheck({
    countryId,
    type: 'header',
  });
  const decoration = {
    left: holydayNameForHeader && (
      <DecorationSpan position="left" holidayName={holydayNameForHeader} />
    ),
    right: holydayNameForHeader && (
      <DecorationSpan position="right" holidayName={holydayNameForHeader} />
    ),
  };
  return (
    <>
      <Media greaterThanOrEqual="notebook">
        {(className, renderChildren) => {
          return renderChildren ? (
            <header id="main-header" className={cn(className, styles.desktop)}>
              <Desktop decoration={decoration} logo={logo} />
            </header>
          ) : null;
        }}
      </Media>
      <Media lessThan="notebook">
        {(className, renderChildren) => {
          return renderChildren ? (
            <header id="main-header" className={cn(className, styles.mobile)}>
              <Mobile decoration={decoration} logo={logo} />
              <MobileNavigation />
            </header>
          ) : null;
        }}
      </Media>
    </>
  );
}
