import './Profile.scss';
import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import * as session from 'modules/session';
import { userEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import { PageContainer } from 'shared/ui/page-container';
import { PageTitle } from 'shared/ui/page-title';
import { LinkTabs } from 'shared/ui/link-tabs';
import { RoutesWithSubRoutes } from 'shared/lib/routes-with-sub-routes';
import RegisterOrAuth from '../../Components/NewCartOrder/Modals/Components/register-auth-modal/RegisterOrAuth';
import styles from './styles.module.scss';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: session.get('user'),
    };
  }

  componentDidMount() {
    userEmitter.addListener('User.Logout.Event', this.userLoginHandler);
    userEmitter.addListener('User.Login.Event', this.userLoginHandler);
    userEmitter.addListener('User.Change', this.userRegisteredHandler);
  }

  componentWillUnmount() {
    userEmitter.removeListener('User.Logout.Event', this.userLoginHandler);
    userEmitter.removeListener('User.Login.Event', this.userLoginHandler);
    userEmitter.removeListener('User.Change', this.userRegisteredHandler);
  }

  userLoginHandler = () => {
    this.setState({
      user: session.get('user'),
    });
  };

  userRegisteredHandler = () => {
    this.setState({
      user: session.get('user'),
    });
  };

  render() {
    const { user } = this.state;
    const { t, routes } = this.props;
    const tabsItems = [
      {
        url: `/profile`,
        title: t('Menu.profile'),
      },
      {
        url: `/profile/orders`,
        title: t('Menu.my_orders'),
      },
      {
        url: `/profile/favorites`,
        title: t('Menu.favorites'),
      },
    ];
    const content = user ? (
      <>
        <header className={styles.header}>
          <PageTitle isCenterOnMobile>{t('AbstractProfile.profile')}</PageTitle>
          <LinkTabs items={tabsItems} />
        </header>

        <RoutesWithSubRoutes routes={routes} />
      </>
    ) : (
      <div className="auth-page main-cart-modal">
        <RegisterOrAuth view="profile" />
      </div>
    );
    return (
      <PageContainer>
        <Helmet title={t('AbstractProfile.profile')} />
        {content}
      </PageContainer>
    );
  }
}

export default withTranslation()(Profile);
