import { useEffect, useRef, useState, MouseEvent } from 'react';
import * as React from 'react';
import api from 'modules/helpers/api';
import { useCheckCountries } from 'src/entities/countries';
import * as session from 'modules/session';
import phoneValidator from 'modules/mask/phoneValidator';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Masked from 'src/frontend/Components/Masked';
import { userEmitter } from 'modules/event-emitters';
import Emitter from 'src/frontend/Components/NewCartOrder/Emitters';
import globalStore from 'modules/global-store';

const checkAvailableInCity = (city: string) =>
  !['khe', 'nikolaev', 'per', 'yuz'].includes(city);

interface IPhoneField {
  name: string;
  label?: string;
  pickup: boolean;
  value: string;
  required: boolean;
  fieldsetClassName?: string;
  invalid?: string;
  className?: string;
  placeholder?: string;
  isBonusPhone?: boolean;
}

interface IPhoneProps {
  field: IPhoneField;
  handleChangeForm: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function Phone({ field, handleChangeForm }: IPhoneProps) {
  const { t } = useTranslation();
  const { isMontenegro } = useCheckCountries();
  const [value, setValue] = useState(field.value || '');
  const [isBonusPhoneCorrect, setIsBonusPhoneCorrect] = useState(false);
  const [isEdit, setisEdit] = useState(true);
  const input = useRef(null);

  const {
    name,
    required,
    className,
    fieldsetClassName,
    invalid,
    placeholder,
    label,
    isBonusPhone,
  } = field;

  const { bonus_phone, phone_bonuses: { discount = 0 } = {} } =
    session.get('cart');
  const { text_id = '' } = globalStore.get('current_city');
  const isAvailableInCity = checkAvailableInCity(text_id);

  const checkPhone = () => {
    return phoneValidator.isValid(value);
  };

  const resetBonusPhone = () => {
    return api('cart.bonus_phone')
      .then((cart) => {
        session.set('cart', cart);
        Emitter.emit('CART_CHANGE', { notChangeUrl: true });
        Emitter.emit('FORM_IS_VALID', false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    userEmitter.addListener('User.Logout.Event', resetBonusPhone);
    setIsBonusPhoneCorrect(checkPhone());
  }, []);

  const change = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    setValue(newValue || '');
    setisEdit(!isBonusPhone && !!newValue);
    handleChangeForm(e);
  };

  const submit = (event: MouseEvent<HTMLLabelElement> | React.FormEvent) => {
    event.preventDefault();
    Emitter.emit('MODAL_CALL', {
      modal: 'RegisterOrAuthModal',
      value: { phone: value },
      info: 'bonus',
    });
  };

  const labelRequiredClass = cn({ required });

  const fieldsetCnStr = `${fieldsetClassName}`;

  const fieldsetClass = cn('input-row phone', {
    [fieldsetCnStr]: fieldsetClassName,
    invalid,
  });

  const isShowApplyDiscountByPhone =
    isBonusPhone && !isBonusPhoneCorrect && !bonus_phone && isAvailableInCity;

  const isShowApplyDiscount =
    ((!bonus_phone && isBonusPhone) || (!bonus_phone && isEdit)) &&
    isAvailableInCity &&
    isBonusPhoneCorrect &&
    !isMontenegro;

  const discountByNumberText = `${t('locale.Discount')} ${discount}% ${t(
    'locale.by_the_number'
  )} ${bonus_phone}`;

  return (
    <fieldset className={fieldsetClass}>
      {!isBonusPhone && (
        <label htmlFor={name} className={labelRequiredClass}>
          {label}
        </label>
      )}
      {isShowApplyDiscountByPhone && (
        <label htmlFor={name} className={labelRequiredClass}>
          {t('locale.apply')} <span>{t('locale.discount')}</span>{' '}
          {t('locale.by_phone')}
        </label>
      )}
      {bonus_phone && isBonusPhone && (
        <label htmlFor={name}>
          {t('locale.Discount')} <span> {discount}% </span>
          {t('locale.by_the_number')} <span> {bonus_phone}</span>
        </label>
      )}
      {isShowApplyDiscount && (
        <label className="right-label" onClick={submit} onKeyDown={submit}>
          {t('locale.apply_discount')}
        </label>
      )}
      <div className="input-row__container">
        <Masked
          type="phone"
          defaultValue={bonus_phone || value}
          name={name}
          patterns={{ '#': /[0-9]/ }}
          value={value}
          onChange={change}
          onValidate={setIsBonusPhoneCorrect}
          required
          className={className || ''}
          disabled={!!bonus_phone}
          placeholder={placeholder}
          forwardRef={input}
          id={name}
        />
        {bonus_phone && <div className="input-btn checked" />}
      </div>

      {bonus_phone && !isBonusPhone && (
        <label className="bottom-label" htmlFor={name}>
          {discountByNumberText}
        </label>
      )}
    </fieldset>
  );
}
