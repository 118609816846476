import { useState } from 'react';
import './style.scss';
import cn from 'classnames';
import { useCheckCountries } from 'src/entities/countries';

function Button(props) {
  const {
    className,
    type = '',
    colorScheme = 'default',
    disabled = false,
    specialStyle = {},
    decoration: {
      add_button_color: btnColor = '',
      card_color: cardColor = '',
    } = {},
    cardType,
    textStyle,
    borderStyle,
    hoverBorder,
    hoverText,
    children,
    onClick,
  } = props;
  const { isUkraine } = useCheckCountries();
  const { button = {}, hover } = specialStyle;
  const isBtnColor = cardType === 'single' || cardType === 'grid';
  const [borderStyleColor, setBorder] = useState(borderStyle);
  const [textColor, setColor] = useState(textStyle);
  const customStyle = isBtnColor
    ? {
        backgroundColor: btnColor,
        borderColor: cardColor,
        color: cardColor,
        fill: textColor,
        stroke: textColor,
      }
    : {};

  const mouseEnter = () => {
    setBorder(hoverBorder);
    setColor(hoverText);
  };

  const mouseLeave = () => {
    setBorder(borderStyle);
    setColor(textStyle);
  };

  const disabledSpecialStyles = specialStyle === false;
  return (
    <button
      type="button"
      className={cn('custom-button', {
        ua: isUkraine,
        grid: cardType === 'grid',
        single: cardType === 'single',
        [className]: className,
        [hover]: hover,
        [disabled]: disabled,
        'custom-btn': btnColor && !disabledSpecialStyles,
        gray: colorScheme === 'gray',
        default: colorScheme !== 'gray',
        round: type === 'round',
      })}
      onClick={onClick}
      style={{
        ...button,
        ...customStyle,
        borderColor: borderStyleColor,
        color: textColor,
        stroke: textColor,
      }}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      {children}
    </button>
  );
}

export default Button;
