import { useSelectCountry } from './selectors';

export const useCheckCountries = () => {
  const { alpha2 } = useSelectCountry();
  return {
    isBelarus: alpha2 === 'BY',
    isRussia: alpha2 === 'RU',
    isMontenegro: alpha2 === 'ME',
    isArmenia: alpha2 === 'HY',
    isUkraine: alpha2 === 'UA' || alpha2 === 'PL', // sushistory
  };
};

export const withCheckCountries = <T extends object>(
  Component: React.ComponentType<T & ReturnType<typeof useCheckCountries>>
) => {
  function CheckCountriesWrapper(props: T) {
    const selectedCountry = useCheckCountries();
    return <Component {...props} {...selectedCountry} />;
  }
  return CheckCountriesWrapper;
};
